import React from 'react';
import { translations } from '../../../../utils/translations';
import { Button, Input, Typography } from 'antd';

const { Title } = Typography;

const ItemCategoryForm = ({ item, setItem, onFinish }) => {
	return (
		<div>
			<Title level={3}>{translations.category}</Title>
			<br />
			<br />
			<Input
				autoFocus
				value={item?.name}
				onChange={({ target: { value } }) => {
					setItem({
						...item,
						name: value,
					});
				}}
				placeholder='Enter Item Category Name'
				style={{
					marginRight: 12,
					marginBottom: 12,
				}}
			/>
			<br />
			<br />
			<Button onClick={onFinish} style={{ width: 100 }}>
				{translations.submit}
			</Button>
		</div>
	);
};

export default ItemCategoryForm;
