import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../router/RouteNames';
import api from '../../utils/http';
import { CuisinesColumns } from '../../columns/CuisinesColumns';
import MyTable from '../UI/MyTable';
import { setCuisine } from '../../redux/Reducer';
import { translations } from '../../utils/translations';

const { Title } = Typography;

const CuisineTable = ({ data, setPageNumber, pageSize, setPageSize, totalPages, extraButtons }) => {
	const navigate = useNavigate();

	return (
		<Flex vertical>
			<Flex justify='space-between' align='center'>
				<Title>{translations.cuisines}</Title>

				<div>
					{extraButtons && <span style={{ marginRight: 20 }}>{extraButtons}</span>}
					<Button
						type='primary'
						onClick={() => {
							navigate(RoutesNames.ADD_CUISINES);
						}}
					>
						{translations.addCuisine}
					</Button>
				</div>
			</Flex>
			<MyTable
				data={data}
				rowKey='id'
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				editPath='/cuisines/edit/'
				basePath='/cuisines/'
				queryParams='id'
				columns={CuisinesColumns}
				setReduxAction={setCuisine}
				totalPages={totalPages}
			/>
		</Flex>
	);
};

export default CuisineTable;
