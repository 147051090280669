import { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Flex, Input, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setLiveOrdersRequests } from '../../../redux/Reducer';
import { ORDER_DECLINE_REASONS } from '../../../utils/constants';

const { Option } = Select;

const times = [
	{
		id: '15',
		value: 15,
	},
	{
		id: '20',
		value: 20,
	},
	{
		id: '25',
		value: 25,
	},
	{
		id: '30',
		value: 30,
	},
	{
		id: '35',
		value: 35,
	},
	{
		id: '40',
		value: 40,
	},
	{
		id: '45',
		value: 45,
	},
	{
		id: '50',
		value: 50,
	},
];

export const LiveOrderRequest = ({
	order,
	liveOrdersRequests,
	onOpen,
	// onClose,
	onRemoveOrderRequest,
	onAccept,
	onDecline,
}) => {
	const [timer, setTimer] = useState(null);
	const [estimatedMinutes, setEstimatedMinutes] = useState(times[5].value);

	const intervalRef = useRef();

	const lorRef = useRef(liveOrdersRequests);

	useEffect(() => {
		lorRef.current = liveOrdersRequests;
	}, [liveOrdersRequests]);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			const secondsLeft = (new Date(order.orderIgnoredDate) - new Date()) / 1000;
			// console.log(secondsLeft);
			setTimer(secondsLeft > 0 ? Math.floor(secondsLeft) : '');
			// console.log({ order });
			if (!(secondsLeft > 0)) {
				// onClose(order.id, lorRef.current);
				// onRemoveOrderRequest(order.id, lorRef.current);
			}
		}, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	const items = [
		{
			key: ORDER_DECLINE_REASONS.WORK.key,
			label: ORDER_DECLINE_REASONS.WORK.label,
		},
		{
			key: ORDER_DECLINE_REASONS.MISSING_PRODUCTS.key,
			label: ORDER_DECLINE_REASONS.MISSING_PRODUCTS.label,
		},
	];

	return (
		<div style={{ minWidth: 100, flex: 1 }}>
			<div>{order.id}</div>
			<div>{order.price}</div>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button onClick={() => onAccept(order, estimatedMinutes)}>Αποδοχή</Button>
					<Select
						value={estimatedMinutes}
						// notFoundContent={loading ? <Spin size='small' /> : null}
						style={{ width: '100%' }}
						// onChange={value => {
						// 	setParentData({ ...parentData, [name]: value?.id || value });
						// }}
						onChange={v => setEstimatedMinutes(v)}
					>
						{times.map(item => (
							<Option key={item.id} value={item.id}>
								{item.value}
							</Option>
						))}
					</Select>
				</div>
				<Flex align='flex-start' gap='small' vertical>
					<Dropdown.Button
						menu={{
							items,
							onClick: onDecline,
						}}
						onClick={() => onDecline(order, undefined)}
					>
						Απόρριψη
					</Dropdown.Button>
				</Flex>
			</div>

			<div>{timer}</div>
		</div>
	);
};
