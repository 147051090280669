import { translations } from '../utils/translations';

export const UsersColumns = [
	{
		title: <span style={{ color: '#6B7280' }}>ID</span>,
		dataIndex: 'id',
		key: 'id',
		width: 500,
	},
	{
		title: <span style={{ color: '#6B7280' }}>{translations.name}</span>,
		dataIndex: 'name',
		key: 'name',
		width: 150,
		render: (text, record) => {
			return <span>{`${record.firstName} ${record.lastName}`}</span>;
		},
	},
	{
		title: <span style={{ color: '#6B7280' }}>{translations.email}</span>,
		dataIndex: 'email',
		key: 'email',
		width: 500,
	},
];
