import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Spin, Switch, Typography } from 'antd';
import LiveOrders from './orders/live-orders/LiveOrders';
import { translations } from '../utils/translations';
import { setShopOpen } from '../redux/Reducer';
import api from '../utils/http';
import { socketSingleton } from '../utils/useSockets';

const { Title } = Typography;

const Home = () => {
	const { user, shopOpen } = useSelector(state => state);

	// console.log('userddd', shopOpen);
	const [loading, setLoading] = useState(null);

	const dispatch = useDispatch();

	const { patch } = api();

	// const toggle = async value => {
	// 	// console.log('toogle', value);
	// 	try {
	// 		if (loading) {
	// 			return;
	// 		}
	// 		setLoading(true);

	// 		const res = await patch(`/${value ? 'open' : 'close'}`);
	// 		if (res?.status === 200) {
	// 			// console.log('will setShopOpen', value);
	// 			dispatch(setShopOpen(value));
	// 			if (value) {
	// 				socketSingleton.connect();
	// 			} else {
	// 				socketSingleton.disconnect();
	// 			}
	// 			// socketSingleton[value ? connect : disconnect]
	// 		}
	// 	} catch (err) {
	// 		console.log(err);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	// console.log('shoooo ---> ', shopOpen);
	return !user.id ? (
		<Flex justify='center' align='center' style={{ height: '10vh' }}>
			<Spin />
		</Flex>
	) : (
		<div style={{ padding: 24 }}>
			{
				user?.role === 0 ? (
					<Flex justify='space-between' align='center' style={{ height: '10vh' }}>
						{/* <Title>Hello Big Kota Channel</Title> */}
					</Flex>
				) : (
					<div style={{ color: 'red', fontSize: 50 }}>dadada</div>
				)
				// (
				// 	<div>
				// 		<Flex justify='space-between' align='center'>
				// 			<Title>{translations.liveOrders}</Title>
				// 			<Switch
				// 				checkedChildren={'Active'}
				// 				unCheckedChildren='Inactive'
				// 				// defaultChecked={true}
				// 				onChange={toggle}
				// 				disabled={loading}
				// 				checked={shopOpen}
				// 				// defaultValue={shopOpen}
				// 			/>
				// 		</Flex>
				// 		<LiveOrders />
				// 	</div>
				// )
			}
		</div>
	);
};

export default Home;
