import React, { useEffect, useState } from 'react';
import { darkTheme, lightTheme } from '../assets/colors';
import { useSelector } from 'react-redux';

export const useTheme = () => {
	const [color, setColor] = useState(darkTheme);
	// const { theme } = useSelector(state => state);

	// useEffect(() => {
	// 	theme === 'dark' ? setColor(darkTheme) : setColor(lightTheme);
	// }, [theme]);

	useEffect(() => {
		setColor(darkTheme);
	}, []);

	return { color };
};
