import { translations } from '../utils/translations';

export const ItemColumns = [
	{
		title: <span style={{ color: '#6B7280' }}>ID</span>,
		dataIndex: 'id',
		key: 'id',
		width: 50,
	},
	{
		title: <span style={{ color: '#6B7280' }}>{translations.name}</span>,
		dataIndex: 'name',
		key: 'name',
		width: 150,
		render: (text, record) => <span>{record.name}</span>,
	},
	{
		title: <span style={{ color: '#6B7280' }}>{translations.price}</span>,
		dataIndex: 'price',
		key: 'price',
		width: 150,
		render: (text, record) => <span>{record.price}</span>,
	},
];
