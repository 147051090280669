import React, { useEffect, useCallback, useState } from 'react';
import { message, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import api from '../../../utils/http';
import { uniqBy } from 'lodash';

const { Option } = Select;

const PaginatedSelect = ({
	basePath,
	user,
	parentData,
	setParentData,
	name,
	params,
	multiple,
	dontCompareShop,
	global,
}) => {
	const { get, getGlobal } = api();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [search, setSearch] = useState('');
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		setPageNumber(1);
	}, [basePath]);

	const fetchDataForUserShop = () => {
		setLoading(true);
		get(basePath, {
			pageNumber,
			...(params && { params }),
			...(search && { name: search }),
			pageSize: 30,
			language: user?.language,
		})
			.then(response => {
				setTotalPages(response.data.totalPages);
				setData(prevData => uniqBy([...prevData, ...response.data.data], 'id'));
				setHasMore(response.data.pageNumber < response.data.totalPages);
				setLoading(false);
				// console.log(response.data.data);
			})
			.catch(error => {
				setLoading(false);
				message.error(error.response.data.message);
			});
	};
	const fetchDataForAdmin = () => {
		console.log(basePath, {
			pageNumber,
			...(params && { params }),
			...(search && { name: search }),
			pageSize: 30,
			language: user?.language,
		});

		let newPath = basePath + '?';

		if (typeof params === 'object' && params) {
			Object.keys(params).forEach(key => {
				if (!params[key]) {
					return;
				}
				newPath += `&${key}=${params[key]}`;
			});
		}
		console.log('params', params);
		setLoading(true);
		const action = global ? getGlobal : get;
		action(newPath, {
			pageNumber,
			// ...(params && { params }),
			...(search && { name: search }),
			pageSize: 300,
			// shop: user?.shop,
			language: user?.language,
		})
			.then(response => {
				setTotalPages(response.data.totalPages);
				setData(prevData => uniqBy([...prevData, ...response.data.data], 'id'));
				setHasMore(response.data.pageNumber < response.data.totalPages);
				setLoading(false);
			})
			.catch(error => {
				setLoading(false);
				message.error(error.response.data.message);
			});
	};

	useEffect(() => {
		search && setData([]);
		user.role === 0 ? fetchDataForAdmin() : fetchDataForUserShop();
	}, [pageNumber, search]);

	const handleSearch = useCallback(
		debounce(value => {
			setSearch(value);
			setPageNumber(1);
			setHasMore(true);
		}, 500),
		[],
	);

	const handleScroll = e => {
		const { target } = e;
		if (
			target.scrollTop + target.clientHeight >= target.scrollHeight - 100 &&
			pageNumber < totalPages &&
			hasMore &&
			!loading
		) {
			setPageNumber(prevPage => prevPage + 1);
		}
	};

	const checkParentDataFormat = parent => {
		if (parent && parent[0]?.name) {
			return parent.map(x => x?.id);
		} else if (parent && parseInt(parent) > 0) {
			return parent;
		} else {
			return [];
		}
	};

	// console.log(dontCompareShop, data);
	let finalData = dontCompareShop
		? data
		: data.filter(it => {
				// console.log(it.shop, it.name);

				return it.shop == user.shop;
			});
	return (
		<Select
			showSearch
			mode={multiple}
			onSearch={handleSearch}
			filterOption={false}
			onPopupScroll={handleScroll}
			value={checkParentDataFormat(parentData?.[name])}
			notFoundContent={loading ? <Spin size='small' /> : null}
			style={{ width: '100%' }}
			onChange={value => {
				setParentData({ ...parentData, [name]: value?.id || value });
			}}
		>
			{finalData
				// .filter(it => console.log(it.shop, user.shop) || it.shop == user.shop)
				.map(item => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
		</Select>
	);
};

export default PaginatedSelect;
