import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCuisine } from '../../redux/Reducer';
import api from '../../utils/http';
import CuisineTable from '../../components/cuisines/CuisineView';
import { Button } from 'antd';
import { ReorderModalCuisines } from '../../components/cuisines/ReorderModalCuisines';

const CuisinesHOC = () => {
	const { user, cuisines } = useSelector(state => state);
	const { getGlobal, patchGlobal } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(70);
	const [totalPages, setTotalPages] = useState(0);

	const [openedMobileReorderNode, setOpenedMobileReorderNode] = useState(false);

	useEffect(() => {
		getGlobal('/cuisines', {
			pageNumber,
			pageSize,
			language: user.language,
		})
			.then(response => {
				response && dispatch(setCuisine(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {});
	}, [pageNumber, pageSize]);

	return (
		<>
			{openedMobileReorderNode && (
				<ReorderModalCuisines
					// openedMobileReorderNode={openedMobileReorderNode}
					close={() => setOpenedMobileReorderNode(false)}
					action={patchGlobal}
					data={cuisines}
				/>
			)}
			<CuisineTable
				data={cuisines}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				totalPages={totalPages}
				extraButtons={
					<Button
						style={{ alignSelf: 'center' }}
						onClick={() => setOpenedMobileReorderNode(true)}
					>
						Αλλαγή σειράς κατηγοριών
					</Button>
				}
			/>
		</>
	);
};

export default CuisinesHOC;
