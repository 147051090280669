import React, { useEffect } from 'react';
import { Button, Card, Form, Input, Select } from 'antd';
import PhoneInput from 'react-phone-number-input';
import { translations } from '../../utils/translations';

const layout = {
	labelCol: {
		span: 4,
	},
	wrapperCol: {
		span: 12,
	},
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: 'Το ${label} είναι υποχρεωτικό',
	types: {
		email: 'Το email δεν είναι σωστό!',
	},
	number: {
		range: 'Το τηλέφωνο δεν είναι σωστό ή δεν έχει 10 ψηφία',
	},
};
/* eslint-enable no-template-curly-in-string */

const UserForm = ({ edit, onFinish, userData, user }) => {
	const [form] = Form.useForm();
	const { innerWidth } = window;

	useEffect(() => {
		form.setFieldsValue({
			firstName: userData?.firstName,
			lastName: userData?.lastName,
			email: userData?.email,
			phone: userData?.phone,
		});
	}, [userData]);

	return (
		<Card
			title={edit ? translations.editUser : translations.addUser}
			style={
				innerWidth > 1000
					? {
							width: '60%',
						}
					: { width: '100%' }
			}
		>
			<Form
				{...layout}
				name='User'
				form={form}
				onFinish={onFinish}
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
				initialValues={{
					firstName: userData?.firstName,
					lastName: userData?.lastName,
					email: userData?.email,
					phone: userData?.phone,
				}}
				validateMessages={validateMessages}
			>
				<Form.Item
					style={{ width: '100%' }}
					name={['firstName']}
					label={translations.firstName}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					style={{ width: '100%' }}
					name={['lastName']}
					label={translations.lastName}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					style={{ width: '100%' }}
					name={['email']}
					label={translations.email}
					rules={[
						{
							required: true,
							type: 'email',
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					style={{ width: '100%' }}
					name={['phone']}
					label={translations.phone}
					rules={[
						{
							required: true,
						},
					]}
				>
					<PhoneInput
						defaultCountry='GR'
						style={{
							border: '1px solid #cecece',
							borderRadius: 6,
							padding: 6,
							height: 24,
						}}
					/>
				</Form.Item>

				<Form.Item
					style={{ width: '100%' }}
					wrapperCol={{
						...layout.wrapperCol,
						offset: 5,
					}}
				>
					<Button
						type='primary'
						style={{ marginRight: 12 }}
						onClick={() => window.history.back()}
					>
						{translations.cancel}
					</Button>

					<Button type='primary' htmlType='submit'>
						{translations.submit}
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};
export default UserForm;
