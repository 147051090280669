import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../router/RouteNames';
import { CuisinesColumns } from '../../columns/CuisinesColumns';
import MyTable from '../UI/MyTable';
import { setCities } from '../../redux/Reducer';
import { translations } from '../../utils/translations';

const { Title } = Typography;

const CityView = ({ data, setPageNumber, pageSize, setPageSize, totalPages }) => {
	const navigate = useNavigate();

	return (
		<Flex vertical>
			<Flex justify='space-between' align='center'>
				<Title>{translations.cities}</Title>

				<Button
					type='primary'
					onClick={() => {
						navigate(RoutesNames.ADD_CITY);
					}}
				>
					{translations.addCity}
				</Button>
			</Flex>

			<MyTable
				data={data}
				rowKey='id'
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				editPath='/cities/edit/'
				basePath='/cities/'
				queryParams='id'
				columns={CuisinesColumns}
				setReduxAction={setCities}
				totalPages={totalPages}
				modalFlag={false}
			/>
		</Flex>
	);
};

export default CityView;
