import React, { useEffect, useState } from 'react';
import UsersTable from '../../components/users/UserView';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setLoading, setUsers } from '../../redux/Reducer';
import api from '../../utils/http';

const UsersHOC = () => {
	const { users } = useSelector(state => state);
	const { getGlobal } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);

	const fetchData = async () => {
		try {
			const response = await getGlobal('/users', { pageNumber, pageSize });
			if (response) {
				dispatch(setUsers(response.data.data));
				setTotalPages(response.data.totalPages);
			}
		} catch (err) {
			dispatch(setError(err.response?.data?.message || 'An error occurred'));
		} finally {
			dispatch(setLoading(false));
		}
	};

	useEffect(() => {
		fetchData();
	}, [pageNumber, pageSize]);

	return (
		<UsersTable
			data={users}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
		/>
	);
};

export default UsersHOC;
