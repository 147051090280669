import React from 'react';
import { Button, Flex } from 'antd';
import { ItemColumns } from '../../../../columns/ItemsColumns';
import MyTable from '../../../UI/MyTable';
import { setItems } from '../../../../redux/Reducer';
import MyModal from '../../../UI/Modal';
import ItemForm from './ItemsForm';
import { translations } from '../../../../utils/translations';

const ItemsView = ({
	data,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	item,
	setItem,
	onFinish,
	open,
	setOpen,
	user,
}) => {
	return (
		<Flex vertical>
			<Flex justify='space-between' align='center'>
				<h2>{translations.items}</h2>

				<Button type='primary' onClick={() => setOpen(true)}>
					{translations.addNew}
				</Button>
			</Flex>
			<br /> <br />
			<MyTable
				data={data.map(it => ({ ...it, price: it.price / 100 }))}
				setOpen={setOpen}
				setItem={setItem}
				modalFlag={true}
				rowKey='id'
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				editPath='/items/edit/'
				basePath={`/items`}
				columns={ItemColumns}
				setReduxAction={setItems}
				queryParams='id'
				totalPages={totalPages}
				user={user}
				draggable={true}
			/>
			<br />
			<br />
			<MyModal setData={setItem} open={open} setOpen={setOpen}>
				<ItemForm
					edit={item?.id}
					item={item}
					setItem={setItem}
					onFinish={onFinish}
					user={user}
				/>
			</MyModal>
		</Flex>
	);
};

export default ItemsView;
