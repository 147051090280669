import { configureStore } from '@reduxjs/toolkit';
import appReducer from './Reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'users',
		'shops',
		'cuisines',
		'itemCategories',
		'items',
		'modifiers',
		'modifierOptions',
		'deals',
		'orders', // den iparxei
		'coupons',
		'cities',
		'liveOrdersRequests',
		'liveOrders',
		'socket',
		'shopOpen',
	],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);
