import { Flex, Switch, Typography } from 'antd';
import { translations } from '../../../utils/translations';
import LiveOrders from '../live-orders/LiveOrders';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../utils/http';
import { useState } from 'react';
import { setShopOpen } from '../../../redux/Reducer';
import { socketSingleton } from '../../../utils/useSockets';
const { Title } = Typography;

export const ShopOrdersUi = () => {
	const { user, shopOpen } = useSelector(state => state);

	// console.log('userddd', shopOpen);
	const [loading, setLoading] = useState(null);

	const dispatch = useDispatch();

	const { patch } = api();

	const toggle = async value => {
		// console.log('toogle', value);
		try {
			if (loading) {
				return;
			}
			setLoading(true);

			const res = await patch(`/${value ? 'open' : 'close'}`);
			if (res?.status === 200) {
				// console.log('will setShopOpen', value);
				dispatch(setShopOpen(value));
				if (value) {
					socketSingleton.connect();
				} else {
					socketSingleton.disconnect();
				}
				// socketSingleton[value ? connect : disconnect]
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Flex justify='space-between' align='center'>
				<Title>{translations.liveOrders}</Title>
				<Switch
					checkedChildren={'Active'}
					unCheckedChildren='Inactive'
					// defaultChecked={true}
					onChange={toggle}
					disabled={loading}
					checked={shopOpen}
					// defaultValue={shopOpen}
				/>
			</Flex>
			<LiveOrders />
		</>
	);
};
