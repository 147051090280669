import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModifierOptions } from '../../../../redux/Reducer';
import api from '../../../../utils/http';
import ModifierOptionsView from '../../../../components/store/shop/modifierOptions/ModifierOptionsView';
import { message } from 'antd';

const ModifiersOptionsHOC = () => {
	const { modifierOptions, user } = useSelector(state => state);
	const { get, patch, put } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(75);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState({
		available: false,
		price: 0,
	});
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!user?.shop || open) {
			return;
		}
		get(`/modifier-options`, {
			pageNumber,
			pageSize,
		})
			.then(response => {
				response && dispatch(setModifierOptions(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {
				message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
			});
	}, [pageNumber, pageSize, open, user]);

	const onFinish = () => {
		if (!item?.name) {
			message.error('Το όνομα είναι κενό');
			return;
		}
		// if (!item?.price && item?.price != 0) {
		// 	message.error('Η τιμή είναι κενή');
		// 	return;
		// }

		const p = Number(`${item.price}`?.replaceAll(',', '.'));
		if (!isFinite(p)) {
			message.error('Η τιμή πρέπει να είναι νούμερο');
			return;
		}
		const action = item?.id ? put : patch;

		const data = {
			name: item.name,
			price: Math.round(p * 100),
			// additionalTax: item.additionalTax * 100 || 0,
			available: !!item.available,
			shop: user.shop,
			language: user.language,
		};
		action(`/modifier-options/${item?.id || ''}`, data)
			.then(response => {
				setOpen(false);
				setItem({});
				message.success('Success!');
			})
			.catch(err => {
				message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
				console.log(err);
			});
	};

	return (
		<ModifierOptionsView
			data={modifierOptions}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			open={open}
			setOpen={setOpen}
			user={user}
		/>
	);
};

export default ModifiersOptionsHOC;
