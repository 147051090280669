import { Flex, Form, Input, InputNumber } from 'antd';
import React, { useEffect } from 'react';
import Schedule from './Schedule';
import PhoneInput from 'react-phone-number-input';
import { translations } from '../../../../utils/translations';

const Shop = ({ setShopData, shopData }) => {
	return (
		<Flex vertical>
			<Flex vertical style={{ marginRight: 6 }}>
				<h4>
					<sup style={{ color: 'red' }}>*</sup>
					{translations.name}
				</h4>
				<Input
					style={{ width: 200 }}
					value={shopData?.name}
					onChange={({ target: { value } }) => {
						setShopData({
							...shopData,
							name: value,
						});
					}}
				/>
			</Flex>
			<br />
			<Flex vertical style={{ marginRight: 6 }}>
				<h4>
					<sup style={{ color: 'red' }}>*</sup>
					{translations.phone}
				</h4>
				<PhoneInput
					value={shopData?.phone}
					defaultCountry='GR'
					style={{
						width: 200,
						border: '1px solid #cecece',
						borderRadius: 6,
						height: 24,
					}}
					onChange={value => {
						setShopData({ ...shopData, phone: value });
					}}
				/>
			</Flex>
			<br />
			<Flex vertical style={{ marginRight: 6 }}>
				<h4>
					<sup style={{ color: 'red' }}>*</sup>
					{translations.schedule}
				</h4>
				<Schedule setShopData={setShopData} shopData={shopData} />
			</Flex>
		</Flex>
	);
};

export default Shop;
