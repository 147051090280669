export const lightTheme = {
	BACKGROUND: '#fff',
	BACKGROUND_SIDER: '#f8f8f8',
	TEXT: '#2A2E34',
	CONTRARY_TEXT: '#fff',
	BUTTON: '#2499A8',
	BORDER: '#d9d9d9',
};

export const darkTheme = {
	BACKGROUND_SIDER: '#171717',
	BACKGROUND: '#212121',
	TEXT: '#fff',
	CONTRARY_TEXT: '#fff',
	BUTTON: '#2499A8',
	BORDER: '#545454',
};
