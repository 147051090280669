export const APP_VERSION = 0.1;

export const ORDER_DECLINE_REASONS = {
	WORK: {
		key: 1,
		label: 'Φόρτος εργασίας',
	},
	MISSING_PRODUCTS: {
		key: 2,
		label: 'Ελλειψη προϊόντων',
	},
};
