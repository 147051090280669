import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setItemCategories } from '../../../../redux/Reducer';
import ItemCategoryTable from '../../../../components/store/shop/itemCategories/ItemCategoryView';
import api from '../../../../utils/http';
import { message } from 'antd';

const ItemCategoriesHOC = () => {
	const { itemCategories, user } = useSelector(state => state);
	const { get, patch, put } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(75);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!user?.shop || open) {
			return;
		}

		get(`/item-categories`, {
			pageNumber,
			pageSize,
			language: user.language,
		})
			.then(response => {
				response && dispatch(setItemCategories(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {
				message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
			});
	}, [pageNumber, pageSize, open, user]);

	const onFinish = () => {
		if (!item?.name) {
			message.error('Το όνομα είναι κενό');
			return;
		}
		if (item.id) {
			put(`/item-categories/${item.id}`, {
				name: item.name,
				language: user.language,
			})
				.then(response => {
					setOpen(!open);
					setItem({});
					message.success('Success!');
				})
				.catch(err => {
					message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
				});
		} else {
			patch(`/item-categories`, {
				name: item.name,
				language: user.language,
			})
				.then(response => {
					setOpen(!open);
					setItem({});
					message.success('Success!');
				})
				.catch(err => {
					message.error(err.response.data.message);
				});
		}
	};

	return (
		<ItemCategoryTable
			data={itemCategories}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			open={open}
			setOpen={setOpen}
			user={user}
		/>
	);
};

export default ItemCategoriesHOC;
