import { EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Collapse, Flex, Select, Typography } from 'antd';
import React, { useState } from 'react';

const { Text } = Typography;

const Order = ({ item, onComplete }) => {
	const { innerWidth } = window;

	const { id, address, comment, items, paymentType } = item || {};

	const [status, setStatus] = useState(0);

	// console.log(item);
	const genExtra = () => (
		<EyeOutlined
			onClick={event => {
				// If you don't want click extra trigger collapse, you can prevent this:
				event.stopPropagation();
			}}
		/>
	);

	const backColors = {
		0: '#f50',
		1: '#3db389',
		2: '#108ee9',
		3: '#9e9e9e',
	};

	return (
		<div style={{ marginBottom: 12 }}>
			<Flex
				justify='space-between'
				style={{
					backgroundColor: backColors[status],
					padding: 4,
					borderTopRightRadius: 6,
					borderTopLeftRadius: 6,
				}}
			>
				<div>
					<div>#{id}</div>
					{/* <Text>{address} </Text> */}
					<br />
					<span>Order:</span>
					<div>{paymentType === 0 ? 'Μετρητά' : 'Κάρτα'}</div>
					<div>{`${item.price / 100}€`}</div>
					<br />
					<br />
					<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 6 }}>
						{items.map((item, index) => (
							<div
								key={index}
								style={{ display: 'flex', flexDirection: 'row', marginBottom: 6 }}
							>
								{item.quantity}x name: {item.name}
							</div>
						))}
					</div>
				</div>
				{/* <Select
					style={innerWidth < 450 ? { width: '50%' } : { width: '20%' }}
					value={status}
					options={[
						{ value: 0, label: 'incoming' },
						{ value: 1, label: 'in Prep' },
						{ value: 2, label: 'Ready' },
						{ value: 3, label: 'delivered' },
					]}
					onChange={value => {
						setStatus(value);
					}}
				/> */}
			</Flex>

			{onComplete && (
				<Button onClick={() => onComplete(item.id)}>Ολοκλήρωση παραγγελίας</Button>
			)}
			{/* <Collapse
				style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
				items={[
					{
						label: 'Details',
						children: <div></div>,
						showArrow: false,
						extra: genExtra(),
					},
				]}
			/> */}
		</div>
	);
};

export default Order;
