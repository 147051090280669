import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCities } from '../../redux/Reducer';
import api from '../../utils/http';
import CityView from '../../components/cities/CityView';

const CityHOC = () => {
	const { user, cities } = useSelector(state => state);
	const { getGlobal } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		getGlobal('/cities', {
			pageNumber,
			pageSize,
			language: user.language,
		})
			.then(response => {
				response && dispatch(setCities(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {});
	}, [pageNumber, pageSize]);

	return (
		<CityView
			data={cities}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
		/>
	);
};

export default CityHOC;
