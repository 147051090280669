import { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { store } from '../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { setLiveOrdersRequests, setSocket } from '../redux/Reducer';

export let socketSingleton = null;

export const useSockets = () => {
	// console.log('useSockets');
	const dispatch = useDispatch();
	const { user, shopOpen, liveOrdersRequests } = useSelector(state => state);

	// const state = useSelector(s => s);
	const lorRef = useRef(liveOrdersRequests);

	useEffect(() => {
		lorRef.current = liveOrdersRequests;
	}, [liveOrdersRequests]);

	useEffect(() => {
		const state = store.getState();
		const token = state?.user?.token;

		// console.log(socketURL, useMainStore.getState().token);
		const authorization = `Bearer ${token || ''}`;

		// console.log(authorization, import.meta.env.VITE_BASE_URL);

		const socket = io(`${import.meta.env.VITE_BASE_URL}`, {
			// autoConnect: state?.user?.role === 1 && !!authorization && shopOpen,
			autoConnect: false,
			extraHeaders: {
				authorization,
				// 'ngrok-skip-browser-warning': 'true',
				// auth: authorization,
				// token: authorization,
			},
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 2000, // 1 second
			reconnectionDelayMax: 5000, // 5 seconds

			// auth: {
			//     authorization: authorization,
			//     auth: authorization,
			//     token: authorization,
			// },

			// query: {
			//     // ?something=dasda
			//     'my-key': 'my-value',
			// },
		}); // ${user?.cityId}

		// const { setSocket } = useSocketStore.getState();
		// socket.io.on('error', error => {
		//     // ...
		//     console.log('error 1');
		// });
		// socket.io.on('reconnect', attempt => {
		//     // ...
		//     console.log('reconnect 2');
		// });
		// socket.io.on('reconnect_attempt', attempt => {
		//     // ...
		//     console.log('reconnect_attempt 3');
		// });
		// socket.io.on('reconnect_error', error => {
		//     // ...
		//     console.log('reconnect_error 4');
		// });
		// socket.io.on('reconnect_failed', () => {
		//     // ...
		//     console.log('reconnect_failed 5');
		// });

		socket.on('connect', () => {
			// ...
			console.log('ggg------------  connect', `, id: ${socket?.id}`);
			console.log(socket);
			console.log(socket.recovered);

			// setTimeout(() => {
			// 	console.log('reccc');
			// 	// if (socket.io.engine) {
			// 	socket.io.engine.close();
			// 	// }

			// 	// socket.disconnect();
			// }, 3000);
		});

		socket.on('connect_error', error => {
			if (socket.active) {
				console.log('ggg------------  connect error, will try again', error);
				// temporary failure, the socket will automatically try to reconnect
			} else {
				console.log('ggg------------  denied, must call manually');
				// the connection was denied by the server
				// in that case, `socket.connect()` must be manually called in order to reconnect
				console.log(error.message);
			}
		});

		socket.on('reconnect_attempt', attemptNumber => {
			console.log(`Reconnection attempt #${attemptNumber}`);
		});

		socket.on('reconnect', attemptNumber => {
			console.log(`Reconnected after ${attemptNumber} attempts`);
		});

		socket.on('reconnect_failed', () => {
			console.log('Reconnection failed');
		});

		// possible reasons sto client api online
		socket.on('disconnect', (reason, details) => {
			console.log('------------  disconnect', reason, details);
		});

		socket.on('ping', () => {});

		socket.on('order-created', (newOrderStr, cb) => {
			// console.log('------------  NEW ORDER strrrrr', newOrderStr);

			const newOrder = JSON.parse(newOrderStr);
			console.log('------------  NEW ORDER', newOrder, cb);
			if (typeof cb === 'function') {
				cb(444);
			}

			const idx = lorRef.current.findIndex(lo => lo.id === newOrder.id);
			if (idx !== -1) {
				console.log('new order exists', lorRef.current);
				return;
			}

			const newLiveOrdersRequests = [...lorRef.current];

			newLiveOrdersRequests.push(newOrder);
			dispatch(setLiveOrdersRequests(newLiveOrdersRequests));
			// onAddOrder(data);
		});

		// socket.onAny((event, ...args) => {
		// 	console.log(`------------ socket got ${event}`);
		// });

		// console.log('sss ', state);
		// dispatch(setSocket(socket));
		console.log('setaro socket');
		socketSingleton = socket;

		return () => {
			socketSingleton = null;
			socket.off();
		};
	}, []);
};
