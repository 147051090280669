import { Flex, Input } from 'antd';
import React from 'react';
import Map from './Map';
import { translations } from '../../../../utils/translations';
import { useSelector } from 'react-redux';

const { TextArea } = Input;

const Address = ({ shopData, setShopData }) => {
	const { user } = useSelector(state => state);
	return (
		<Flex vertical>
			<br />
			<h4>{translations.street}</h4>
			<Input
				value={shopData?.address?.street}
				style={{ marginRight: 6, width: '50svw' }}
				onChange={({ target: { value } }) => {
					setShopData({
						...shopData,
						areas: [],
						address: {
							...shopData.address,
							street: value,
						},
					});
				}}
			/>
			<br />
			<h4>{translations.streetNumber}</h4>
			<Input
				style={{ marginRight: 6, width: '50svw' }}
				value={shopData?.address?.streetNumber}
				onChange={({ target: { value } }) => {
					setShopData({
						...shopData,
						areas: [],
						address: {
							...shopData.address,
							streetNumber: value,
						},
					});
				}}
			/>
			<br />
			<h4>{translations.area}</h4>
			<Input
				style={{ marginRight: 6, width: '50svw' }}
				value={shopData?.address?.area}
				onChange={({ target: { value } }) => {
					setShopData({
						...shopData,
						areas: [],
						address: {
							...shopData.address,
							area: value,
						},
					});
				}}
			/>
			<br />
			<br />
			<h3>{translations.mapExplain}</h3>
			<h3>{translations.mapExplain2}</h3>

			<Map
				data={shopData}
				// data={{
				// 	...shopData,
				// 	areas: !shopData.areas
				// 		? shopData.areas
				// 		: shopData.areas.map?.(a => ({
				// 				...a,
				// 				// minimumOrder: a.minimumOrder / 100,
				// 			})),
				// }}
				setData={setShopData}
			/>
			<br />
			<h3>{translations.notes}</h3>
			<TextArea
				rows={4}
				placeholder='notes'
				style={{ width: 300 }}
				value={shopData?.address?.notes}
				onChange={({ target: { value } }) => {
					setShopData({
						...shopData,
						address: {
							...shopData.address,
							notes: value,
						},
					});
				}}
			/>
			<br />
			{user.role === 0 && (
				<Flex vertical>
					{/* <h3>{translations.comments}</h3> */}
					<h3>Comments</h3>
					<TextArea
						rows={4}
						placeholder='comments'
						style={{ width: 300 }}
						value={shopData?.comments}
						onChange={({ target: { value } }) => {
							setShopData({
								...shopData,
								comments: value,
							});
						}}
					/>
				</Flex>
			)}
			<br />
		</Flex>
	);
};

export default Address;
