import React from 'react';
import { Button, Flex, Input, Typography } from 'antd';
import { ItemCategoriesColumns } from '../../../../columns/ItemCategoriesColumns';
import MyTable from '../../../UI/MyTable';
import { setItemCategories } from '../../../../redux/Reducer';
import MyModal from '../../../UI/Modal';
import { translations } from '../../../../utils/translations';
import ItemCategoryForm from './ItemCategoryForm';

const ItemCategoryTable = ({
	data,
	setPageNumber,
	pageSize,
	setPageSize,
	totalPages,
	item,
	setItem,
	onFinish,
	open,
	setOpen,
	user,
}) => {
	return (
		<Flex vertical>
			<Flex justify='space-between' align='center'>
				<h2>{translations.categories}</h2>
				<Button type='primary' onClick={() => setOpen(true)}>
					{translations.addNew}
				</Button>
			</Flex>
			<br />
			<br />

			<MyTable
				data={data}
				setOpen={setOpen}
				modalFlag={true}
				setItem={setItem}
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				basePath={`/item-categories/`}
				queryParams='id'
				columns={ItemCategoriesColumns}
				setReduxAction={setItemCategories}
				totalPages={totalPages}
				user={user}
			/>
			<br />
			<br />
			<MyModal setData={setItem} open={open} setOpen={setOpen}>
				<ItemCategoryForm item={item} setItem={setItem} onFinish={onFinish} />
			</MyModal>
		</Flex>
	);
};

export default ItemCategoryTable;
