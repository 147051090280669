import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Flex, message, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import api from '../../../utils/http';
import { translations } from '../../../utils/translations';

const UploadImage = ({ parentData, setParentData, array }) => {
	const [loading, setLoading] = useState(false);
	const { postGlobal } = api();

	const beforeUpload = (file, type) => {
		setLoading(true);
		const formData = new FormData();
		formData.append('file', file);
		formData.append('type', type);
		postGlobal('/files', formData)
			.then(data => {
				if (!data.data.id) {
					return;
				}
				message.success(`${file.name} file uploaded successfully`);
				setLoading(false);
				setParentData({
					...parentData,
					files:
						parentData.files?.length > 0
							? [
									...parentData.files,
									{
										id: data.data.id,
										name: data.data.name,
										type,
									},
								]
							: [
									{
										id: data.data.id,
										name: data.data.name,
										type,
									},
								],
				});
			})
			.catch(err => {
				setLoading(false);
				message.error(err.response?.data.message);
			});
		return false;
	};

	return (
		<Flex justify='center' align='center' style={{ width: '100%' }}>
			{!loading ? (
				<Flex justify='center' align='center'>
					{array.map((type, index) => (
						<Flex key={type}>
							{parentData?.files?.find(el => el.type === type)?.name && (
								<Flex vertical>
									<Flex
										justify='space-between'
										align='center'
										style={{ maxWidth: '90%' }}
									>
										<DeleteOutlined
											style={{ fontSize: 18 }}
											onClick={() => {
												setParentData({
													...parentData,
													files: parentData.files.filter(
														el => el.type !== type,
													),
												});
											}}
										/>
									</Flex>
									<img
										key={index}
										src={`${import.meta.env.VITE_SPACE_URL}${
											parentData?.files?.find(el => el.type === type)?.name
										}`}
										alt='shop'
										style={{
											width: '20svw',
											objectFit: 'cover',
											objectPosition: 'center',
											marginRight: 12,
										}}
									/>
								</Flex>
							)}
							{!parentData?.files?.find(el => el.type === type)?.name && (
								<Flex vertical style={{ marginRight: 8 }}>
									<Upload
										name='file'
										beforeUpload={file => beforeUpload(file, type)}
										showUploadList={false}
										progress={{ strokeWidth: 2 }}
									>
										<Button type='primary'>
											<span style={{ marginRight: 8 }}>{type}</span>
											<UploadOutlined />
										</Button>
									</Upload>
								</Flex>
							)}
						</Flex>
					))}
				</Flex>
			) : (
				<Spin />
			)}
		</Flex>
	);
};

export default UploadImage;
