import React from 'react';
import { Table } from 'antd';
const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
	},
];
const data = [
	{
		key: 1,
		name: 'John Brown',
		address: 'New York No. 1 Lake Park',
		description:
			'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
	},
	{
		key: 2,
		name: 'Jim Green',
		address: 'London No. 1 Lake Park',
		description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
	},
	{
		key: 3,
		name: 'Not Expandable',
		address: 'Jiangsu No. 1 Lake Park',
		description: 'This not expandable',
	},
	{
		key: 4,
		name: 'Joe Black',
		age: 32,
		address: 'Sydney No. 1 Lake Park',
		description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
	},
];
const HistoryOrders = () => (
	<div>
		<h2>History Orders</h2>
		<Table
			columns={columns}
			expandable={{
				expandedRowRender: record => (
					<p
						style={{
							margin: 0,
						}}
					>
						{record.description}
					</p>
				),
				rowExpandable: record => record.name !== 'Not Expandable',
			}}
			dataSource={data}
		/>
	</div>
);
export default HistoryOrders;
