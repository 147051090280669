import { Modal } from 'antd';
import React from 'react';

const MyModal = ({ open, setOpen, setData, children }) => {
	return (
		<Modal
			open={open}
			footer={null}
			onCancel={() => {
				setData({});
				setOpen(false);
			}}
		>
			{children}
		</Modal>
	);
};

export default MyModal;
