import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShops } from '../../redux/Reducer';
import api from '../../utils/http';
import ShopsView from '../../components/shops/ShopView';
import { Flex, message, Spin } from 'antd';

const ShopsHOC = () => {
	const { shops } = useSelector(state => state);
	const { getGlobal } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(75);

	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		getGlobal('/shops', { pageNumber, pageSize })
			.then(response => {
				if (response?.data?.data?.length > 0) {
					dispatch(setShops(response.data.data));
				}
				setTotalPages(response?.data?.totalPages);
			})
			.catch(err => {
				message.error(err.response?.data.message);
			});
	}, [pageNumber, pageSize]);

	console.log({ shops });
	return shops ? (
		<ShopsView
			data={shops}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
		/>
	) : (
		<Flex justify='center' align='center'>
			<Spin />
		</Flex>
	);
};

export default ShopsHOC;
