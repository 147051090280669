import React, { useEffect } from 'react';
import { Button, message, Tabs } from 'antd';
import Info from './components/Info';
import api from '../../../utils/http';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab, setUser } from '../../../redux/Reducer';
import ItemCategoriesHOC from '../../../containers/shopOwner/shop/itemCategories/ItemCategoriesHOC';
import ItemsHOC from '../../../containers/shopOwner/shop/items/ItemsHOC';
import ModifiersHOC from '../../../containers/shopOwner/shop/modifiers/ModifiersHOC';
import ModifiersOptionsHOC from '../../../containers/shopOwner/shop/modifierOptions/ModifierOptionsHOC';
import DealsHOC from '../../../containers/shopOwner/shop/deals/DealsHOC';
import CouponsHOC from '../../../containers/shopOwner/shop/coupons/CouponsHOC';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesNames } from '../../../router/RouteNames';
import LiveOrders from '../../orders/live-orders/LiveOrders';
import { translations } from '../../../utils/translations';

const ShopForm = ({ shopData, setShopData, user }) => {
	const { put, post, postGlobal } = api();
	const navigate = useNavigate();
	const { id } = useParams();
	const { currentTab } = useSelector(state => state);
	const dispatch = useDispatch();

	useEffect(() => {
		id && dispatch(setUser({ ...user, shop: parseInt(id) }));
	}, []);

	const onInfoUpdate = () => {
		try {
			const isAdd = window.location.pathname.split('/')[2] === 'add';
			const action = isAdd ? postGlobal : put;

			console.log('aaaaa', shopData.schedule);
			const data = {
				...shopData,
				user: {
					...shopData.user,
					language: user.language,
				},
				schedule: shopData.schedule.map((day, index) => {
					// if (day[0] === '0000' && day[1] === '0000') {
					// 	return [];
					// }
					if (!shopData.scheduleOpen?.[index]) {
						return [];
					}
					return day.map(time => time.replace(':', ''));
				}),
				scheduleOpen: undefined,
				files: shopData?.files?.map(file => file.id),
				cuisines: shopData?.cuisines?.[0]?.id
					? shopData?.cuisines?.map(cuisine => cuisine.id)
					: shopData?.cuisines,
				areas: !shopData.areas
					? shopData.areas
					: shopData.areas.map(item => {
							const pmo = Number(`${item.minimumOrder}`?.replaceAll(',', '.'));
							if (!isFinite(pmo)) {
								// message.error('Η τιμή πρέπει να είναι νούμερο');
								throw new Error('Η τιμή πρέπει να είναι νούμερο');
							}
							const pdc = Number(`${item.deliveryCost}`?.replaceAll(',', '.'));
							if (!isFinite(pdc)) {
								// message.error('Η τιμή πρέπει να είναι νούμερο');
								throw new Error('Η τιμή πρέπει να είναι νούμερο');
							}
							return {
								...item,
								minimumOrder: Math.round(pmo * 100),
								deliveryCost: Math.round(pdc * 100),
							};
						}),
			};
			if (!isAdd) {
				data.cuisines = shopData?.cuisines?.[0]?.id
					? shopData?.cuisines?.map(cuisine => cuisine.id)
					: shopData?.cuisines;
			}
			action(isAdd ? '/shops' : '', data)
				.then(res => {
					if (isAdd) {
						message.success('Shop added successfully');
						navigate(RoutesNames.SHOP);
						return;
					}
					message.success('Shop updated successfully');
				})
				.catch(err => {
					message.error(err.response.data.message);
				});
		} catch (err) {
			console.error(err);
		}
	};

	const tabItems =
		window.location.pathname.split('/')[2] === 'add'
			? [
					{
						key: '1',
						label: translations.info,
						children: (
							<Info
								shopData={shopData}
								setShopData={setShopData}
								edit={false}
								onFinish={onInfoUpdate}
								user={user}
							/>
						),
					},
				]
			: [
					{
						key: '1',
						label: translations.info,
						children: (
							<Info
								shopData={shopData}
								setShopData={setShopData}
								edit={true}
								onFinish={onInfoUpdate}
								user={user}
							/>
						),
					},
					// {
					//   key: "2",
					//   label: "Orders",
					//   children: <LiveOrders />,
					// },
					{
						key: '3',
						label: translations.categories,
						children: <ItemCategoriesHOC />,
					},
					{
						key: '4',
						label: translations.items,
						children: <ItemsHOC />,
					},
					{
						key: '5',
						label: translations.modifiers,
						children: <ModifiersHOC />,
					},
					{
						key: '6',
						label: translations.modifierOptions,
						children: <ModifiersOptionsHOC />,
					},
					{
						key: '7',
						label: translations.coupons,
						children: <CouponsHOC />,
					},
					// {
					//   key: "8",
					//   label: "Deals",
					//   children: <DealsHOC />,
					// },
				];

	return (
		<div>
			{user.role === 0 && (
				<div style={{ float: 'right' }}>
					<Button
						type='dashed'
						onClick={() => {
							navigate(-1);
							dispatch(setUser({ ...user, shop: null }));
						}}
					>
						{translations.cancel}
					</Button>
				</div>
			)}
			<Tabs
				style={{ width: '100%' }}
				defaultActiveKey={currentTab}
				onChange={key => {
					dispatch(setCurrentTab(key));
				}}
				items={tabItems}
			/>
		</div>
	);
};

export default ShopForm;
