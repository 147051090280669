import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useSockets } from './useSockets';
import api from './http';
import { setLiveOrders, setLiveOrdersRequests } from '../redux/Reducer';

export const useLiveOrders = () => {
	// console.log('useLiveOrders');

	const { user, liveOrders, liveOrdersRequests } = useSelector(state => state);

	const dispatch = useDispatch();
	const { get, patch } = api();

	useSockets();

	useEffect(() => {
		// get(`/orders?status=0`)
		// 	.then(res => {
		// 		console.log(res);
		// 		if (res?.data?.data) {
		// 			dispatch(setLiveOrdersRequests(res.data.data));
		// 		}
		// 	})
		// 	.catch(err => {
		// 		console.log(err);
		// 	});
		get(`/orders?status=1`)
			.then(res => {
				console.log(res);
				if (res?.data?.data) {
					dispatch(setLiveOrders(res.data.data));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}, []);
	// console.log('sa', socketSingleton);

	const onRemoveOrderRequest = (id, refOrders) => {
		const orders = refOrders || liveOrdersRequests;
		const idx = orders.findIndex(lor => lor.id === id);
		if (idx === -1) {
			return;
		}
		console.log('was ', idx, orders);

		const newLiveOrdersRequests = [...orders];
		newLiveOrdersRequests.splice(idx, 1);
		console.log('is ', newLiveOrdersRequests);

		dispatch(setLiveOrdersRequests(newLiveOrdersRequests));
	};

	const onAccept = (order, estimatedMinutes) => {
		patch(`/orders/${order.id}/accept`, {
			estimatedMinutes,
			// name: item.name,
			// language: user.language,
		})
			.then(response => {
				console.log(response);

				const idx = liveOrdersRequests.findIndex(lo => lo.id === order.id);
				console.log('idx', idx);
				if (idx === -1) {
					onRemoveOrderRequest(order.id);

					// console.log('new order exists', liveOrdersRequests);
					return;
				}

				const newLiveOrders = [...liveOrders];
				newLiveOrders.push(liveOrdersRequests[idx]);
				dispatch(setLiveOrders(newLiveOrders));

				onRemoveOrderRequest(order.id);
				// setOpen(!open);
				// setItem({});
				message.success('Success!');
			})
			.catch(err => {
				console.log('ERRORRRR', err);
				if (err.response?.data?.code === 404) {
					message.error('Η παραγγελία δεν υπάρχει ή έχει απαντηθεί');
					onRemoveOrderRequest(order.id);
					return;
				}
				message.error(err.response?.data?.message);
				// console.log('ERRORRRR', err.response?.data?.code);
				// message.error(err.response?.data?.code);
			});
	};

	const onDecline = (order, reasonObj) => {
		const { key } = reasonObj || {};
		const reason = key && `${key}`;
		// console.log(order, reason);
		patch(`/orders/${order.id}/decline`, {
			...(reason && { reason }),
			// name: item.name,
			// language: user.language,
		})
			.then(response => {
				console.log(response);
				onRemoveOrderRequest(order.id);

				// setOpen(!open);
				// setItem({});
				message.success('Success!');
			})
			.catch(err => {
				console.log('ERRORRRR', err);

				if (err.response?.data?.code === 404) {
					message.error('Η παραγγελία δεν υπάρχει ή έχει απαντηθεί');
					onRemoveOrderRequest(order.id);
					return;
				}
				// console.log('ERRORRRR', err.response?.data?.code);
				message.error(err.response?.data?.message);
			});
	};

	// const onAddOrder = newOrder => {
	// 	const idx = liveOrders.find(lo => lo.id === newOrder);
	// 	if (!(idx === -1)) {
	// 		console.log('new order exists');
	// 		return;
	// 	}

	// 	const newLiveOrders = [...liveOrders];
	// 	newLiveOrders.push(newOrder);
	// 	dispatch(setLiveOrders(newLiveOrders));
	// };

	return { liveOrdersRequests, liveOrders, onRemoveOrderRequest, onAccept, onDecline };
};
