import { RoutesNames } from '../../../router/RouteNames';
import { translations } from '../../../utils/translations';
import { Divider, Drawer, Flex, Typography } from 'antd';
import {
	HistoryOutlined,
	LogoutOutlined,
	SettingOutlined,
	SunFilled,
	UnorderedListOutlined,
} from '@ant-design/icons';
import { useTheme } from '../../../utils/useTheme';
import { useSelector } from 'react-redux';
import { setCurrentTab } from '../../../redux/Reducer';
import { APP_VERSION } from '../../../utils/constants';

const { Title } = Typography;

export const MyDrawer = ({
	open,
	onClose,
	dispatch,
	navigate,
	setCurrent,
	setTheme,
	setUser,
	setOpenDrawer,
}) => {
	const { color } = useTheme();
	const { user, theme } = useSelector(state => state);

	return (
		<Drawer
			open={open}
			onClose={onClose}
			title={`${translations.hello} ${user?.firstName} ${user?.lastName}`}
		>
			<Flex vertical align='flex-start' style={{ height: '85svh', rowGap: 30 }}>
				<div>
					{/* {(user?.role === 1 || user?.role === 3) && (
						<Flex
							style={{ cursor: 'pointer' }}
							onClick={() => {
								navigate(RoutesNames.HOME);
								setOpenDrawer(false);
							}}
						>
							<Title level={5}>
								<UnorderedListOutlined style={{ marginRight: 12, fontSize: 22 }} />
								{translations.liveOrders}
							</Title>
						</Flex>
					)}
					<br /> */}
					{/* {(user?.role === 1 || user?.role === 3) && (
						<Flex
							style={{ cursor: 'pointer' }}
							onClick={() => {
								navigate(RoutesNames.HISTORY);
								setOpenDrawer(false);
							}}
						>
							<Title level={5}>
								<HistoryOutlined style={{ marginRight: 12, fontSize: 22 }} />
								{translations.orderHistory}
							</Title>
						</Flex>
					)}
					<br /> */}
					{/* {(user?.role === 1 || user?.role === 3) && (
						<Flex
							style={{ cursor: 'pointer' }}
							onClick={() => {
								dispatch(setCurrentTab('1'));
								navigate('/shop/' + user?.shop);
								setOpenDrawer(false);
							}}
						>
							<Title level={5}>
								<SettingOutlined style={{ marginRight: 12, fontSize: 22 }} />
								{translations.myShop}
							</Title>
						</Flex>
					)} */}

					{(user?.role === 1 || user?.role === 3) && (
						<Flex
							style={{ cursor: 'pointer' }}
							onClick={() => {
								window.location.reload();
							}}
						>
							<Title level={5}>
								<SettingOutlined style={{ marginRight: 12, fontSize: 22 }} />
								Ανανέωση
							</Title>
						</Flex>
					)}
				</div>
				{/* <Flex
					style={{ cursor: 'pointer' }}
					onClick={() => {
						dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'));
					}}
				>
					<SunFilled style={{ marginRight: 12, fontSize: 22, color: color.TEXT }} />
					<span style={{ color: color.TEXT, fontSize: 18 }}>
						{theme === 'dark'
							? translations.activateLightMode
							: translations.activateDarkMode}
					</span>
				</Flex> */}
				<Flex
					style={{ cursor: 'pointer' }}
					onClick={() => {
						dispatch(setUser({}));
						setOpenDrawer(false);
						setCurrent('/');
					}}
				>
					<LogoutOutlined style={{ marginRight: 12, fontSize: 22, color: color.TEXT }} />
					<span style={{ fontSize: 18, color: color.TEXT }}>{translations.logout}</span>
				</Flex>
				<Flex
					style={{ cursor: 'pointer', opacity: 0.6 }}
					onClick={() => {
						window.location.reload();
					}}
				>
					<Typography level={5}>App version: {APP_VERSION}</Typography>
				</Flex>
			</Flex>
		</Drawer>
	);
};
