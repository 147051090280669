import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../router/RouteNames';
import { shopsColumns } from '../../columns/ShopsColumns';
import MyTable from '../UI/MyTable';
import { setShops } from '../../redux/Reducer';
import { translations } from '../../utils/translations';

const { Title } = Typography;

const ShopsView = ({ data, pageNumber, setPageNumber, pageSize, setPageSize, totalPages }) => {
	const navigate = useNavigate();

	return (
		<Flex vertical>
			<Flex justify='space-between' align='center'>
				<Title>{translations.shops}</Title>

				<Button
					type='primary'
					onClick={() => {
						navigate(RoutesNames.ADD_SHOP);
					}}
				>
					{translations.addShop}
				</Button>
			</Flex>

			<MyTable
				data={data}
				rowKey='id'
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				editPath='/shop/'
				basePath='/shops/'
				queryParams='id'
				columns={shopsColumns}
				setReduxAction={setShops}
				totalPages={totalPages}
				draggable={false}
			/>
		</Flex>
	);
};

export default ShopsView;
