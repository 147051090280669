import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	Collapse,
	Flex,
	Tree,
	message,
	Tag,
	Typography,
	Select,
	Switch,
	Divider,
	Button,
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { translations } from '../../utils/translations';

const { Text } = Typography;

const labels = {
	categories: 'Αλλαγή σειράς',
	category: 'Κατηγορία: ',
	item: 'Προιόν: ',
	modifier: 'Γκρουπ υλικών: ',
};

export const ReorderModalCuisines = ({ close, action, data }) => {
	const [modifiedCopy, setModifiedCopy] = useState(data);

	const onSaveReorder = async () => {
		try {
			// console.log('modifiedCopy ', modifiedCopy);

			const itemsMapped = modifiedCopy.map(c => c.id);

			let res;

			res = await action('/cuisines/reorder', {
				cuisines: itemsMapped,
			});

			getTreeData();
			close();
		} catch (err) {
			console.log(err);
		}
	};
	// console.log('original ', children);
	// console.log('modifiedCopy ', modifiedCopy);

	const moveTop = index => {
		const newArr = [...modifiedCopy];
		if (index > 0 && index < newArr.length) {
			// Remove the item at the current index
			const item = newArr.splice(index, 1)[0];
			// Insert the item at the previous index
			newArr.splice(index - 1, 0, item);
		}
		setModifiedCopy(newArr);
	};

	const moveBottom = index => {
		const newArr = [...modifiedCopy];
		if (index >= 0 && index < newArr.length - 1) {
			// Remove the item at the current index
			const item = newArr.splice(index, 1)[0];
			// Insert the item at the next index
			newArr.splice(index + 1, 0, item);
		}
		setModifiedCopy(newArr);
	};

	return (
		<Modal open footer={null} onCancel={close}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
				<Text>Αλλαγή σειράς κουζινών</Text>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						rowGap: 8,
						marginTop: 50,
						paddingLeft: 30,
						paddingRight: 30,
					}}
				>
					{modifiedCopy.map((it, idx) => {
						return (
							<div
								key={it.id}
								style={{
									height: 30,
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								{idx !== 0 ? (
									<UpOutlined
										onClick={() => moveTop(idx)}
										style={{ fontSize: 20 }}
									/>
								) : (
									<div style={{ width: 20 }} />
								)}
								<Text style={{ flex: 1, textAlign: 'center' }}>
									{it.name || it.label}
								</Text>
								{idx !== data.length - 1 ? (
									<DownOutlined
										onClick={() => moveBottom(idx)}
										style={{ fontSize: 20 }}
									/>
								) : (
									<div style={{ width: 20 }} />
								)}
							</div>
						);
					})}
				</div>
				<Button
					type='primary'
					onClick={onSaveReorder}
					style={{ alignSelf: 'center', marginTop: 24 }}
				>
					{translations.submit}
				</Button>
			</div>
		</Modal>
	);
};
