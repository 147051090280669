import { translations } from '../utils/translations';

export const shopsColumns = [
	{
		title: <span style={{ color: '#6B7280' }}>ID</span>,
		dataIndex: 'id',
		key: 'id',
		width: 50,
	},
	{
		title: <span style={{ color: '#6B7280' }}>{translations.name}</span>,
		dataIndex: 'name',
		key: 'name',
		width: 150,
		render: (text, record) => <span>{record.name}</span>,
	},
	// {
	//   title: <span style={{ color: "#6B7280" }}>{translations.name}</span>,
	//   dataIndex: "phone",
	//   key: "phone",
	//   width: 500,
	// },
];
