import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLiveOrders } from '../../../utils/useLiveOrders';
import { LiveOrderRequest } from './LiveOrderRequest';
import Order from '../order/Order';
import { setLiveOrdersRequests } from '../../../redux/Reducer';

export const LiveOrdersRequests = () => {
	// console.log('LiveOrdersRequests');
	const { user, shopOpen } = useSelector(state => state);
	const { liveOrdersRequests, onRemoveOrderRequest, onAccept, onDecline } = useLiveOrders();

	// const [selectedOrder, setSelectedOrder] = useState(null);

	// useEffect(() => {
	// 	if (!selectedOrder) {
	// 		if (liveOrdersRequests?.length) {
	// 			setSelectedOrder(liveOrdersRequests[0]);
	// 			return;
	// 		}
	// 	} else {
	// 	}
	// }, [liveOrdersRequests]);

	// const onOpen = order => {
	// 	setSelectedOrder(order);
	// };

	// const onClose = (id, refOrders) => {
	// 	const orders = refOrders || liveOrdersRequests;
	// 	console.log('will close', orders?.length);
	// 	if (orders?.length === 1) {
	// 		setSelectedOrder(null);
	// 		return;
	// 	}
	// 	if (orders?.length > 1) {
	// 		const ord = orders.find(lo => lo.id !== id);
	// 		console.log('found ', ord || null);

	// 		setSelectedOrder(ord || null);
	// 	}
	// };

	if (!(user?.role === 1)) {
		return null;
	}

	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					position: 'fixed',
					bottom: 30,
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					columnGap: 14,
					// marginLeft: '10%',
					alignItems: 'center',
					backgroundColor: 'red',
					overflowX: 'auto',
				}}
			>
				{/* <div>{shopOpen ? 'online' : 'off'}</div> */}
				{/* <div> length is {liveOrdersRequests?.length}</div> */}

				{liveOrdersRequests.map(lor => (
					<LiveOrderRequest
						key={lor.id}
						order={lor}
						liveOrdersRequests={liveOrdersRequests}
						// onOpen={onOpen}
						onRemoveOrderRequest={onRemoveOrderRequest}
						// onClose={onClose}
						onAccept={(order, estimatedMinutes) => {
							onAccept(order, estimatedMinutes);
							// if (selectedOrder.id === lor.id) {
							// 	setSelectedOrder(
							// 		liveOrdersRequests?.length ? liveOrdersRequests[0] : null,
							// 	);
							// }
						}}
						onDecline={reason => {
							onDecline(lor, reason);
							// if (selectedOrder.id === lor.id) {
							// 	setSelectedOrder(
							// 		liveOrdersRequests?.length ? liveOrdersRequests[0] : null,
							// 	);
							// }
						}}
					/>
				))}
			</div>
		</div>
	);
};
